import React, { useEffect, useState, useRef } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Container, Button } from '../Layout';
import { gridContainerStyle } from '../CreateDeviceContent/styles';
import { Divider } from '@mui/material';
import Grid from '@mui/material/Grid';
import { CircularProgress } from '@mui/material';
import i18n from '../../helpers/i18n';
import { getEmployees, getDevice, getLocationsAndCnpj, editDevice } from '../CreateDeviceContent/CreateDeviceContent.queries';
import { useFormik } from 'formik';
import { validationSchema } from '../CreateDeviceContent/validationSchema';
import { RestrictionSection, AuthorizedCollaboratorsSection, ControllerSection, DeviceSection } from '../CreateEditSections/';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { deviceTypes, filterTypes } from '../../helpers/consts/deviceConstants';
import { useNavigate } from 'react-router-dom';
import { useError } from '../SnackbarError/ErrorContext';
import DeviceUpdatedAlert from '../DeviceUpdatedAlert/DeviceUpdatedAlert';
import { usePermissions } from '../../hooks/usePermissions';

const EditDeviceContent = ({ onDeviceNameFetched }) => {
    const queryClient = useQueryClient();

    const { id } = useParams();
    const navigate = useNavigate();

    const [offset, setOffset] = useState(0);
    const [byName, setByName] = useState('');
    const [employees, setEmployees] = useState([]);

    const [locations, setLocations] = useState([]);
    const [cnpjs, setCnpjs] = useState([]);

    const { showError } = useError();

    const [isOpenUpdatedAlert, setIsOpenUpdatedAlert] = useState(false);
    const [isCnpjUpdated, setIsCnpjUpdated] = useState(false);

    const { data: dataDevice } = useQuery({
        queryKey: [id],
        queryFn: () => getDevice({ identity: id }),
        retry: false,
        refetchOnWindowFocus: false
    });

    const { data: dataEmployees, isLoading: isLoadingEmployees } = useQuery({
        queryKey: ['funcionarios', offset, byName],
        queryFn: () => getEmployees({ limit: 50, offset, byName }),
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: false
    });

    const { data: dataLocationsAndCnpj, isLoading: isLoadingLocationsAndCnpjs } = useQuery({
        queryKey: ['locations'],
        queryFn: getLocationsAndCnpj,
        refetchOnWindowFocus: false,
        retry: false
    });

    const { permissions } = usePermissions();

    const formik = useFormik({
        initialValues: {
            identity: '',
            deviceType: '',
            cnpj: '',
            name: '',
            company: '',
            timezone: '',
            activationKey: '',
            active: true,
            expirationDate: false,
            startPeriod: null,
            endPeriod: null,
            deleted: false,

            collaboratorsType: 'by_employee',
            employees: [],
            locations: [],

            //Crontroller
            enable_password_punch: false, //Ativar batida por matrícula e senha
            break_device: false, //Ativar dispositivo de pausa NR17
            enable_auto_update: false, //Ativar atualização automática (Tablet Ahgora)
            default_computer: false, //Ativar batida online padrão por link
            timezone_filter: false,  //Ativar fuso horário como filtro              
            triple_validation: false, //Ativar validação tripla para múltiplos usuários
            enable_punches_logs: false, //ativar logs de batidas
            enable_punch_backup: false, //Ativar download de arquivo com batida de ponto (Solicitar donwload do arquivo)
            blink: false,
            smile: false,
            head_movement: false,

            //Restriction
            online_only: false, //Com o dispositivo conectado à internet
            auto_timezone: false, //Com data, hora e fuso automáticos
            only_with_location: false, //Com geolocalização no dispositivo
            only_inside_geofence: false, //Somente dentro do posto de trabalho
            range_ip: false,
            ips: [], //Ip Range '123.123.123.23, 123.123.123.24'
            useSelectAllEmployees: false,
        },
        validationSchema,
        onSubmit: values => {
            mutationEditDevice.mutate({ identity: id, deviceData: values, queryClient });
        }
    });

    const prevDataDeviceRef = useRef();
    useEffect(() => {
        if (dataDevice && prevDataDeviceRef.current !== dataDevice) {
            prevDataDeviceRef.current = dataDevice;

            onDeviceNameFetched(dataDevice.name);

            console.log(dataDevice)

            formik.setValues({
                ...formik.values,
                identity: dataDevice.identity,
                company: dataDevice.company,
                deviceType: dataDevice.device_type || '',
                name: dataDevice.name || '',
                timezone: dataDevice.timezone || '',
                activationKey: dataDevice.activationKey || '',
                active: dataDevice.active ?? true,
                expirationDate: dataDevice.startPeriod ? true : false,
                startPeriod: dataDevice.startPeriod ? dayjs.unix(dataDevice.startPeriod) : null,
                endPeriod: dataDevice.endPeriod ? dayjs.unix(dataDevice.endPeriod) : null,
                collaboratorsType: dataDevice.locations?.length > 0 ? 'by_location' : 'by_employee',
                employees: dataDevice.employees || [],
                locations: dataDevice.locations || [],
                deleted: dataDevice.deleted ?? false,

                // Controller
                enable_password_punch: dataDevice.enable_password_punch ?? false,
                break_device: dataDevice.break_device ?? false,
                enable_auto_update: dataDevice.enable_auto_update ?? false,
                default_computer: dataDevice.default_computer ?? false,
                timezone_filter: dataDevice.timezone_filter ?? false,
                triple_validation: dataDevice.triple_validation ?? false,
                enable_punches_logs: dataDevice.enable_punches_logs ?? false,
                enable_punch_backup: dataDevice.enable_punch_backup ?? false,
                blink: dataDevice.blink ?? false,
                smile: dataDevice.smile ?? false,
                head_movement: dataDevice.head_movement ?? false,

                // Restriction
                online_only: dataDevice.online_only ?? false,
                auto_timezone: dataDevice.auto_timezone ?? false,
                only_with_location: dataDevice.only_with_location ?? false,
                only_inside_geofence: dataDevice.only_inside_geofence ?? false,
                range_ip: dataDevice.range_ip ? true : false,
                ips: dataDevice.range_ip ? dataDevice.range_ip.split(',') : [],
            });
        }
    }, [dataDevice, formik, onDeviceNameFetched]);

    useEffect(() => {
        if (dataLocationsAndCnpj?.locations) {
            let locations = dataLocationsAndCnpj.locations;

            if (typeof locations === 'object' && !Array.isArray(locations)) {
                locations = Object.values(locations);
            }

            const locationArray = locations.map(location => ({ value: location, title: location }));
            setLocations(locationArray);
            setCnpjs(dataLocationsAndCnpj.cnpjs.map(cnpj => ({ value: cnpj, title: cnpj })));
        }
    }, [dataLocationsAndCnpj]);

    useEffect(() => {
        if (cnpjs.length > 0 && !formik.values.cnpj && !isCnpjUpdated) {
            if (!dataDevice?.cnpj) {
                formik.setFieldValue('cnpj', cnpjs[0].value);
                setIsCnpjUpdated(true);
            } else {
                formik.setFieldValue('cnpj', dataDevice.cnpj);
                setIsCnpjUpdated(true);
            }
        }
    }, [cnpjs, formik, dataDevice, isCnpjUpdated]);

    useEffect(() => {
        if (dataEmployees?.data) {
            const newEmployees = dataEmployees.data.map(employee => ({
                value: employee.dados.matricula,
                title: `${employee.dados.matricula} | ${employee.dados.nome}`
            }));

            setEmployees(prevEmployees => {
                const uniqueEmployees = newEmployees.filter(newEmployee =>
                    !prevEmployees.some(existingEmployee => existingEmployee.value === newEmployee.value)
                );
                return [...prevEmployees, ...uniqueEmployees];
            });
        }
    }, [dataEmployees, byName]);

    const mutationEditDevice = useMutation({
        mutationFn: editDevice,
        onSuccess: (data) => {
            if (!data.response.result && data.response.errorType) {
                let errorMsg = i18n.t('pages.errors.device.createDefault');

                // Tratamento de erros específicos que vem do pw retornando 200 como status
                switch (data.response.errorType) {
                    case 'existsName':
                        errorMsg = i18n.t('pages.errors.device.existsName');
                        break;
                    default:
                        errorMsg = i18n.t('pages.errors.device.createWithType', { errorType: data.response.errorType });
                }

                showError(errorMsg);
                return;
            }

            setIsOpenUpdatedAlert(true);
        },
        onError: (e) => {
            showError(i18n.t('pages.errors.device.createDefault'));
        },
    });

    const handleScroll = (event) => {
        if (isLoadingEmployees || byName) return;
        const { scrollHeight, scrollTop, clientHeight } = event.target;
        if (scrollTop + clientHeight >= scrollHeight - 5) {
            setOffset(prevOffset => prevOffset + 1);
        }
    };

    const handleFilterSearch = (searchText) => {
        setByName(searchText); 
    };

    function handleEditedDevice() {
        setIsOpenUpdatedAlert(false);
        navigate('/');
    }

    if (!dataDevice) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', paddingTop: '100px' }}>
            <CircularProgress />
        </div>;
    }

    return (
        <>
            <DeviceUpdatedAlert isOpen={isOpenUpdatedAlert} onClose={handleEditedDevice} />
            <Container>
                <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
                    <Grid sx={gridContainerStyle} container spacing={10}>

                        <DeviceSection
                            formik={formik}
                            cnpjs={cnpjs}
                            deviceTypes={deviceTypes}
                            isLoadingLocationsAndCnpjs={isLoadingLocationsAndCnpjs}
                            variant='edit'
                            deviceArchived={dataDevice?.deleted || false} 
                        />

                        <AuthorizedCollaboratorsSection
                            formik={formik}
                            employees={employees}
                            locations={locations}
                            isLoadingLocationsAndCnpjs={isLoadingLocationsAndCnpjs}
                            handleFilterSearch={handleFilterSearch}
                            handleScroll={handleScroll}
                            isLoadingEmployees={isLoadingEmployees}
                            filterTypes={filterTypes}
                            deviceArchived={dataDevice?.deleted || false}

                        />

                        <ControllerSection
                            formik={formik}
                            variant='edit'
                            permissions={permissions}
                            deviceArchived={dataDevice?.deleted || false}
                        />

                        {permissions?.permite_restricao_batida && (
                            <RestrictionSection
                                formik={formik}
                                deviceArchived={dataDevice?.deleted || false}
                                permissions={permissions}
                            />
                        )}
                    </Grid>
                    <Divider style={{ marginBottom: 16 }} />
                    <Button type="outlined" onClick={(e) => {
                        e.preventDefault();
                        navigate('/');
                    }}>{i18n.t('pages.newDevice.cancel')}</Button>
                    <Button type="submit" disabled={dataDevice?.deleted || false}>{i18n.t('pages.newDevice.editDevice')}</Button>
                </form>
            </Container>
        </>
    );
};

export default EditDeviceContent;
