import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import api from '../../api';
import endpoints from '../../helpers/endpoints/index';
import i18n from '../../helpers/i18n';
import ActionButtons from '../ActionsButtons/ActionsButtons';
import CopyButton from '../CopyButton';
import DeviceStatus from '../DeviceStatus';
import DeviceName from '../DeviceName';
import Toolbar from '../Toolbar';
import { fetchDevices, removeRegistration, fetchArchivedDevices } from './DeviceTable.queries';
import DeviceDeleteConfirmationAlert from '../DeviceDeleteConfirmationAlert';
import { useError } from '../SnackbarError/ErrorContext';

const DeviceTable = ({ filters }) => {
    const queryClient = useQueryClient();

    const { filterDate, filterDevice, filterStatus } = filters;

    const [selectionModel, setSelectionModel] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [showArchived, setShowArchived] = useState(false);
    const [filteredRows, setFilteredRows] = useState([]);
    const [permissions, setPermissions] = useState(undefined);
    
    const [deviceMap, setDeviceMap] = useState({});

    const { showError } = useError();

    const [showDeleteConfirmationAlert, setShowDeleteConfirmationAlert] = useState(false);

    const { data: rows, isLoading, isError, error } = useQuery({
        queryKey: ['devices', '/', 'dispositivos-online'],
        queryFn: fetchDevices,
        retry: true,
        refetchOnWindowFocus: true,
        cacheTime: 1000 * 60 * 10, 
        staleTime: 1000 * 60 * 5,
        enabled: permissions === undefined
    });

    const { data: archivedDevices, isLoading: isLoadingArchived } = useQuery({
        queryKey: ['archivedDevices'], // Chave única para os dispositivos arquivados
        queryFn: fetchArchivedDevices,
        enabled: showArchived, // Só ativa a query se o showArchived for true
        cacheTime: 1000 * 60 * 10, // Cache por 10 minutos
    });

    const mutationRemoveRegistration = useMutation({
        mutationFn: removeRegistration,
        onSuccess: (response) => {
            const id = response.identity;
            setDeviceMap(prev => ({
                ...prev,
                [id]: { ...prev[id], archived: true }
            }));
            setShowDeleteConfirmationAlert(true);
        },
        onError: (e) => {
            showError(`Falha ao remover dispositivo, ${e}`);
        },
    });

    const loadDevices = (devices) => {
        const map = devices.reduce((acc, device) => {
            acc[device.id] = device;
            return acc;
        }, {});
        setDeviceMap(map);
    };
    const validateDate = (date) => {
        if (!date || date === '-') return ''
        const [dia, mes, ano] = date.split('/').map(Number);
        const adjustDate = new Date(ano, mes - 1, dia);
        return adjustDate
    }

    const [columns, setColumns] = useState([
        { field: 'dispositivo', 
            headerName: i18n.t('table.device'), 
            width: 200, 
            editable: false, 
            sortable: true, 
            checked: true, 
            flex: 1,
            renderCell: (params) => <DeviceName value={params.row.dispositivo} id={params.row.id}/>},
        { field: 'tipoDispositivo', headerName: i18n.t('table.deviceType'), width: 200, editable: false, checked: true },
        { 
            field: 'status', 
            headerName: i18n.t('table.status'), 
            width: 100, 
            editable: false, 
            sortable: true, 
            checked: true,
            renderCell: (params) => <DeviceStatus value={params.row.status} />},
        {
            field: 'dataCadastro',
            headerName: i18n.t('table.createDate'),
            width: 140,
            editable: false,
            checked: true,
            type: 'date',
            valueGetter: (params) => validateDate(params)
        },
        {
            field: 'dataAtivacao',
            headerName: i18n.t('table.activateDate'),
            width: 140,
            editable: false,
            checked: true,
            type: 'date',
            valueGetter: (params) => validateDate(params)
        },
        {
            field: 'dataSincronia',
            headerName: i18n.t('table.syncDate'),
            width: 143,
            editable: false,
            checked: true,
            type: 'date',
            valueGetter: (params) => validateDate(params)
        },
        {
            field: 'chave',
            headerName: i18n.t('table.key'),
            width: 120,
            headerAlign: 'center',
            align: 'center',
            editable: false,
            sortable: false,
            checked: true,
            renderCell: (params) => <CopyButton value={params.row.chave} />
        },
        {
            field: 'ações',
            headerName: i18n.t('table.actions'),
            width: 120,
            headerAlign: 'center',
            align: 'center',
            editable: false,
            sortable: false,
            checked: true,
            renderCell: (params) => {return (
                <ActionButtons 
                    id={params.id} 
                    isShowArchived={params.row.archived}
                    nomeComputador={params.row.dispositivo}
                    identity={params.id}
                    onDeleteDevice={() => handleOnDelete(params.id)}
                />
            )}
        }
    ]);

    useEffect(() => {
        const getPermissions = async () => {
            if (!permissions) {
                const { data } = await api.get(endpoints.getPermissions);
                setPermissions(data);
            }
        }

        if (!permissions) {
            getPermissions();
        }
    }, [permissions]);

    useEffect(() => {
        if(rows){
            loadDevices(rows);
        }   
    }, [rows]);

    useEffect(() => {
        if (archivedDevices && showArchived) {
            const archivedMap = archivedDevices.reduce((acc, device) => {
                acc[device.id] = device;
                return acc;
            }, {});
            setDeviceMap(prev => ({ ...prev, ...archivedMap }));
        }
    }, [archivedDevices, showArchived]);

    useEffect(() => {
        const filtered = Object.values(deviceMap).filter(row => {
            const matchesSearch = ignoreDiacritics(row.dispositivo.toLowerCase()).includes(ignoreDiacritics(searchText.toLowerCase())) ||
                                ignoreDiacritics(String(row.chave).toLowerCase()).includes(ignoreDiacritics(searchText.toLowerCase()));

            const matchesArchived = showArchived ? row.archived : !row.archived;
            const matchesDate = !filterDate.start || !filterDate.end ||
                (parseDate(row.dataCadastro) >= new Date(filterDate.start) && parseDate(row.dataCadastro) <= new Date(filterDate.end));
            const matchesDevice = filterDevice.length === 0 || filterDevice.map(device => device.toLowerCase()).includes(row.tipoDispositivo.toLowerCase());
            const matchesStatus = !filterStatus || row.status.toLowerCase() === filterStatus.toLowerCase();

            return matchesSearch && matchesArchived && matchesDate && matchesDevice && matchesStatus;
        });

        setFilteredRows(filtered);
    }, [deviceMap, searchText, showArchived, filters, filterDevice, filterDate, filterStatus]);

    function parseDate(dateStr) {
        const [day, month, year] = dateStr.split('/').map(Number);
        return new Date(year, month - 1, day);
    }

    const handleToggleArchived = () => {
        setShowArchived(prev => !prev);
    };

    function ignoreDiacritics(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    function handleOnDelete(identity) {
        mutationRemoveRegistration.mutate({identity, queryClient});
    }

    const onSelectionChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);
        setSelectedData(rows.filter(row => newSelectionModel.includes(row.id)));
    };

    const handleSearch = (text) => {
        setSearchText(text);
    };

    const handleChangeColumns = (columns) => {
        setColumns(columns);
    };

    const getCheckedRows = () => {
        return columns.filter(column => column.checked);
    }

    if (isLoading || isLoadingArchived) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', paddingTop: '100px' }}>
            <CircularProgress />
        </div>
    }

    if (isError) {
        return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', paddingTop: '100px' }}>
            Erro ao carregar os dados: {error.message
            }</div>;
    }

    return (
        <>
            <DeviceDeleteConfirmationAlert isOpen={showDeleteConfirmationAlert} onClose={() => setShowDeleteConfirmationAlert(false)} />

            <Box sx={{
                margin: '16px',
                width: 'calc(100% - 34px)',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                zIndex: 1,
                padding: 0,
                '.evenRow': {
                    backgroundColor: 'var(--ahg-waterloo-lighten5)',
                    font: 'normal normal 400 14px/24px Open Sans',
                    color: 'var(--ahg-gray-darken1)'
                },
                '.oddRow': {
                    backgroundColor: 'white',
                    font: 'normal normal 400 14px/24px Open Sans',
                    color: 'var(--ahg-gray-darken1)'
                }
            }}>
                <Toolbar
                    selectedData={selectedData}
                    onSearch={handleSearch}
                    onChangeColumns={handleChangeColumns}
                    columns={columns}
                    onToggleArchived={handleToggleArchived}
                    isShowArchived={showArchived}
                />
                <DataGrid
                    rows={filteredRows}
                    autoHeight
                    slots={{
                        noRowsOverlay: () => (<span>Nenhum dispositivo</span>)
                    }}
                    columns={getCheckedRows(columns)}
                    initialState={{
                        ...rows.initialState,
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    localeText={{
                        footerRowSelected: (count) =>
                            count !== 1
                                ? `${count.toLocaleString()} ${i18n.t('table.footerRowSelecteds')}`
                                : `${count.toLocaleString()} ${i18n.t('table.footerRowSelected')}`,
                    }}
                    slotProps={{
                        pagination: {
                            labelRowsPerPage: i18n.t('table.labelRowsPerPage'),
                            labelDisplayedRows: ({ from, to, count }) => `${from}–${to} ${i18n.t('table.of')} ${count.toLocaleString()}`,
                        }
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    disableColumnMenu
                    disableColumnResize
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'
                    }
                    onRowSelectionModelChange={onSelectionChange}
                    selectionModel={selectionModel}
                    sx={{
                        '& .MuiDataGrid-withBorderColor': {
                            borderStyle: 'none',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            font: 'normal normal 600 14px/24px Open Sans',
                            color: 'var(--ahg-waterloo-darken2)',
                            letterSpacing: '0.65px'
                        },
                        '& .MuiDataGrid-footerContainer': {
                            backgroundColor: '#fff',
                        },
                        '& .MuiTablePagination-selectLabel': {
                            fontFamily: 'Open Sans',
                            color: 'var(--ahg-waterloo-darken1)',
                            fontSize: '14px'
                        },
                        '& .MuiTablePagination-displayedRows': {
                            fontFamily: 'Open Sans',
                            color: 'var(--ahg-waterloo-darken1)',
                            fontSize: '14px'
                        },
                        '& .MuiTablePagination-actions': {
                            fontFamily: 'Open Sans',
                            fontSize: '14px'
                        },
                        '& .MuiSelect-select': {
                            fontFamily: 'Open Sans',
                            fontSize: '14px'
                        },
                        '& .MuiMenuItem-root, .MuiTablePagination-menuItem': {
                            fontFamily: 'Open Sans',
                            fontSize: '14px'
                        },
                        '& .MuiBox-root': {
                            margin: '16px',
                            width: 'calc(100% - 34px)',
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            zIndex: 1,
                            padding: 0
                        },
                        '& .MuiDataGrid-filler': {
                            height: 'auto !important'
                        },
                        '& .MuiDataGrid-overlayWrapper': {
                            height: '20px',
                            textAlign: 'center',
                            alignItems: 'center',
                            padding: '18px'
                        },
                        '& .MuiDataGrid-scrollbar': {
                            overflow: 'hidden'
                        },
                        '& .MuiDataGrid-root': {
                            margin: '20px 0 70px',
                        },
                    }}
                />
            </Box>
        </>
    );
};

export default DeviceTable;
